import { SharedModelState } from '../shared.model.state';

export enum TeamType {
  DEFAULT = 'default',
  HIDDEN = 'hidden',
  FLEXPOOL = 'flexpool',
}
// tslint:disable-next-line:no-empty-interface
export interface TeamState extends SharedModelState<TeamModel> {}

export interface TeamModel {
  id: string;
  account_id?: string;
  department_id: string;
  name: string;
  color: string;
  color_is_dark?: boolean;
  color_rgb?: string;
  order: string;
  hidden: boolean;
  deleted_date: string;
  deleted: boolean;
  type: TeamType;
  created: string;
  created_by?: string;
  updated?: string;
  modified_by?: string;
}
