import { SubscriptionModel } from '../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { UnsafeAction as Action } from '../interfaces';
import { CancelAccountRequest } from './account.model';

const ACTION_PREFIX = '[Account]';
export const accountActionType = {
  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCEEDED: `${ACTION_PREFIX} Fetch Succeeded`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  PATCH: `${ACTION_PREFIX} Patch account`,
  PATCH_SUCCEEDED: `${ACTION_PREFIX} Patch account succeeded`,
  PATCH_FAILED: `${ACTION_PREFIX} Patch account failed`,

  FETCH_SUBSCRIPTION_PLAN: `${ACTION_PREFIX} Fetch Subscription Plan`,
  FETCH_SUBSCRIPTION_PLAN_SUCCEEDED: `${ACTION_PREFIX} Fetch Subscription Plan Succeeded`,
  FETCH_SUBSCRIPTION_PLAN_FAILED: `${ACTION_PREFIX} Fetch Subscription Plan Failed`,

  FETCH_INVOICE_SETTINGS: `${ACTION_PREFIX} Fetch Invoice Settings`,
  FETCH_INVOICE_SETTINGS_SUCCEEDED: `${ACTION_PREFIX} Fetch Invoice Settings Succeeded`,
  FETCH_INVOICE_SETTINGS_FAILED: `${ACTION_PREFIX} Fetch Invoice Settings Failed`,

  PATCH_INVOICE_SETTINGS: `${ACTION_PREFIX} Patch Invoice Settings `,
  PATCH_INVOICE_SETTINGS_SUCCEEDED: `${ACTION_PREFIX} Patch Invoice Settings succeeded`,
  PATCH_INVOICE_SETTINGS_FAILED: `${ACTION_PREFIX} Patch Invoice Settings failed`,

  LOAD_FILES: `${ACTION_PREFIX} Load files`,
  LOAD_FILES_SUCCESS: `${ACTION_PREFIX} Load files Success`,
  LOAD_FILES_FAILED: `${ACTION_PREFIX} Load files Failed`,

  ADD_FILE: `${ACTION_PREFIX} Add file`,
  ADD_FILE_SUCCESS: `${ACTION_PREFIX} Add file Success`,
  ADD_FILE_FAILED: `${ACTION_PREFIX} Add file Failed`,

  EDIT_FILE: `${ACTION_PREFIX} Edit file`,
  EDIT_FILE_SUCCESS: `${ACTION_PREFIX} Edit file Success`,
  EDIT_FILE_FAILED: `${ACTION_PREFIX} Edit file Failed`,

  DELETE_FILE: `${ACTION_PREFIX} Delete file`,
  DELETE_FILE_SUCCESS: `${ACTION_PREFIX} Delete file Success`,
  DELETE_FILE_FAILED: `${ACTION_PREFIX} Delete file Failed`,

  CANCEL_ACCOUNT: `${ACTION_PREFIX} Cancel account`,
  CANCEL_ACCOUNT_SUCCESS: `${ACTION_PREFIX} Cancel account Success`,
  CANCEL_ACCOUNT_FAILED: `${ACTION_PREFIX} Cancel account Failed`,

  TOGGLE_SUPPORT_ACCESS: `${ACTION_PREFIX} Toggle support access account`,
  TOGGLE_SUPPORT_ACCESS_SUCCESS: `${ACTION_PREFIX} Toggle support access Success`,
  TOGGLE_SUPPORT_ACCESS_FAILED: `${ACTION_PREFIX} Toggle support access Failed`,

  FETCH_NOTIFICATION_SETTINGS: `${ACTION_PREFIX} Fetch notification settings`,
  FETCH_NOTIFICATION_SETTINGS_SUCCESS: `${ACTION_PREFIX} Fetch notification settings success`,
  FETCH_NOTIFICATION_SETTINGS_FAILED: `${ACTION_PREFIX} Fetch notification settings failed`,

  UPDATE_NOTIFICATION_SETTINGS: `${ACTION_PREFIX} Update notification settings`,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS: `${ACTION_PREFIX} Update notification settings success`,
  UPDATE_NOTIFICATION_SETTINGS_FAILED: `${ACTION_PREFIX} Update notification settings failed`,

  SET_DEFAULT_PERMISSION_GROUP: `${ACTION_PREFIX} Set default permission group`,
  UPDATE_ACCOUNT_MANAGER: `${ACTION_PREFIX} Update account manager`,

  RESUME_SUBSCRIPTION_SUCCESS: `${ACTION_PREFIX} Resume subscription success`,
};

export class AccountAction {
  public static fetch(): Action {
    return {
      type: accountActionType.FETCH,
    };
  }

  public static fetchSucceeded(response: any): Action {
    return {
      type: accountActionType.FETCH_SUCCEEDED,
      payload: response,
    };
  }

  public static fetchFailed(response: any): Action {
    return {
      type: accountActionType.FETCH_FAILED,
      payload: response,
    };
  }

  public static fetchSubscription(): Action {
    return {
      type: accountActionType.FETCH_SUBSCRIPTION_PLAN,
    };
  }

  public static fetchSubscriptionSucceeded(response: SubscriptionModel): Action {
    return {
      type: accountActionType.FETCH_SUBSCRIPTION_PLAN_SUCCEEDED,
      payload: response,
    };
  }

  public static fetchSubscriptionFailed(response: any): Action {
    return {
      type: accountActionType.FETCH_FAILED,
      payload: response,
    };
  }

  public static patch(accountId: string, data: any): Action {
    return {
      type: accountActionType.PATCH,
      payload: {
        accountId,
        data,
      },
    };
  }

  public static patchSucceeded(response: any): Action {
    return {
      type: accountActionType.PATCH_SUCCEEDED,
      payload: response,
    };
  }

  public static patchFailed(response: any): Action {
    return {
      type: accountActionType.PATCH_FAILED,
      payload: response,
    };
  }

  public static fetchInvoiceSettings(): Action {
    return {
      type: accountActionType.FETCH_INVOICE_SETTINGS,
    };
  }

  public static fetchInvoiceSettingsSucceeded(response: any): Action {
    return {
      type: accountActionType.FETCH_INVOICE_SETTINGS_SUCCEEDED,
      payload: response,
    };
  }

  public static fetchInvoiceSettingsFailed(response: any): Action {
    return {
      type: accountActionType.FETCH_INVOICE_SETTINGS_FAILED,
      payload: response,
    };
  }

  public static patchInvoiceSettings(accountId: string, data: any): Action {
    return {
      type: accountActionType.PATCH_INVOICE_SETTINGS,
      payload: {
        accountId,
        data,
      },
    };
  }

  public static patchInvoiceSettingsSucceeded(response: any): Action {
    return {
      type: accountActionType.PATCH_INVOICE_SETTINGS_SUCCEEDED,
      payload: response,
    };
  }

  public static patchInvoiceSettingsFailed(response: any): Action {
    return {
      type: accountActionType.PATCH_INVOICE_SETTINGS_FAILED,
      payload: response,
    };
  }

  public static loadFiles(): Action {
    return {
      type: accountActionType.LOAD_FILES,
    };
  }

  public static loadFilesSuccess(response) {
    return {
      type: accountActionType.LOAD_FILES_SUCCESS,
      payload: response,
    };
  }

  public static loadFilesFailed(err) {
    return {
      type: accountActionType.LOAD_FILES_FAILED,
      payload: err,
    };
  }

  public static deleteFile(attachmentId: string) {
    return {
      type: accountActionType.DELETE_FILE,
      payload: {
        attachmentId,
      },
    };
  }

  public static deleteFileSuccess(attachmentId: string) {
    return {
      type: accountActionType.DELETE_FILE_SUCCESS,
      payload: {
        attachmentId,
      },
    };
  }

  public static deleteFileFailed(attachmentId: string) {
    return {
      type: accountActionType.DELETE_FILE_FAILED,
      payload: {
        attachmentId,
      },
    };
  }

  public static addFile(attachment: any) {
    return {
      type: accountActionType.ADD_FILE,
      payload: {
        attachment,
      },
    };
  }

  public static addFileSuccess(response: any) {
    return {
      type: accountActionType.ADD_FILE_SUCCESS,
      payload: response,
    };
  }

  public static addFileFailed(attachment: any) {
    return {
      type: accountActionType.ADD_FILE_FAILED,
      payload: {
        attachment,
      },
    };
  }

  public static editFile(attachment: any) {
    return {
      type: accountActionType.EDIT_FILE,
      payload: {
        attachment,
      },
    };
  }

  public static editFileSuccess(response: any) {
    return {
      type: accountActionType.EDIT_FILE_SUCCESS,
      payload: response,
    };
  }

  public static editFileFailed(attachment: any) {
    return {
      type: accountActionType.EDIT_FILE_FAILED,
      payload: {
        attachment,
      },
    };
  }

  public static cancelAccount(data: CancelAccountRequest) {
    return {
      type: accountActionType.CANCEL_ACCOUNT,
      payload: {
        data,
      },
    };
  }

  public static cancelAccountSuccess(response: any) {
    return {
      type: accountActionType.CANCEL_ACCOUNT_SUCCESS,
      payload: response,
    };
  }

  public static cancelAccountFailed() {
    return {
      type: accountActionType.CANCEL_ACCOUNT_FAILED,
    };
  }

  public static toggleSupportAccess() {
    return {
      type: accountActionType.TOGGLE_SUPPORT_ACCESS,
    };
  }

  public static toggleSupportAccessSuccess(response: any) {
    return {
      type: accountActionType.TOGGLE_SUPPORT_ACCESS_SUCCESS,
      payload: response,
    };
  }

  public static toggleSupportAccessFailed() {
    return {
      type: accountActionType.TOGGLE_SUPPORT_ACCESS_FAILED,
    };
  }

  public static updateNotificationSettings() {
    return {
      type: accountActionType.UPDATE_NOTIFICATION_SETTINGS,
    };
  }

  public static updateNotificationSettingsSuccess(response: any) {
    return {
      type: accountActionType.UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
      payload: response,
    };
  }

  public static updateNotificationSettingsFailed() {
    return {
      type: accountActionType.UPDATE_NOTIFICATION_SETTINGS_FAILED,
    };
  }

  public static fetchNotificationSettings() {
    return {
      type: accountActionType.FETCH_NOTIFICATION_SETTINGS,
    };
  }

  public static fetchNotificationSettingsSuccess(response: any) {
    return {
      type: accountActionType.FETCH_NOTIFICATION_SETTINGS_SUCCESS,
      payload: response,
    };
  }

  public static fetchNotificationSettingsFailed() {
    return {
      type: accountActionType.FETCH_NOTIFICATION_SETTINGS_FAILED,
    };
  }

  public static setDefaultPermissionGroup(group: string) {
    return {
      type: accountActionType.SET_DEFAULT_PERMISSION_GROUP,
      payload: group,
    };
  }

  public static updateAccountManager(userId: string) {
    return {
      type: accountActionType.UPDATE_ACCOUNT_MANAGER,
      payload: userId,
    };
  }

  public static resumeSubscriptionSuccess() {
    return {
      type: accountActionType.RESUME_SUBSCRIPTION_SUCCESS,
    };
  }
}
